<template>
  <section class="listing--detail-calculator">
    <div class="title--text">
      {{ $t('general.mortgageCalculator') }}
    </div>
    <div class="calculator--content">
      <form
        class="d-flex flex-wrap col-12 col-md-6"
        :class="{ 'border-right': $mq !== 'xs' }"
        @submit.prevent="submitCalculation"
      >
        <div
          class="form--group col-6 d-block"
          :class="{ 'has-error': validation.hasError('purchasePrice') }"
        >
          <label for="purchasePrice">{{ $t('mortgage.purchasePrice') }}</label>
          <cleave
            class="basic--input mt-2"
            id="purchasePrice"
            name="purchasePrice"
            placeholder="Purchase Price"
            v-model="purchasePrice"
            :options="cleaveOptionsPrice"
          ></cleave>
          <div class="val-error mt-2" v-if="validation.hasError('purchasePrice')">
            {{ validation.firstError('purchasePrice') }}
          </div>
        </div>
        <div
          class="form--group col-6 d-block"
          :class="{ 'has-error': validation.hasError('loanAmount') }"
        >
          <label for="loanAmount">{{ $t('mortgage.mortgageAmount') }}</label>
          <cleave
            class="basic--input mt-2"
            id="loanAmount"
            name="loanAmount"
            placeholder="Mortgage Amount"
            v-model="loanAmount"
            :options="cleaveOptionsPrice"
          />
          <div class="val-error mt-2" v-if="validation.hasError('loanAmount')">
            {{ validation.firstError('loanAmount') }}
          </div>
        </div>
        <div
          class="form--group col-6 d-block"
          :class="{ 'has-error': validation.hasError('interestRate') }"
        >
          <label for="interestRate">{{ $t('mortgage.interestRate') }}</label>
          <input
            class="basic--input mt-2"
            id="interestRate"
            name="interestRate"
            type="text"
            placeholder="Interest Rate (%)"
            v-model="interestRate"
          />
          <div class="val-error mt-2" v-if="validation.hasError('interestRate')">
            {{ validation.firstError('interestRate') }}
          </div>
        </div>
        <div
          class="form--group col-6 d-block"
          :class="{ 'has-error': validation.hasError('tenureInYear') }"
        >
          <label for="tenureInYear">{{ $t('mortgage.loanTenure') }}</label>
          <input
            class="basic--input mt-2"
            id="tenureInYear"
            name="tenureInYear"
            type="text"
            placeholder="Loan Tenure (Years)"
            v-model="tenureInYear"
          />
          <div class="val-error mt-2" v-if="validation.hasError('tenureInYear')">
            {{ validation.firstError('tenureInYear') }}
          </div>
        </div>
      </form>
      <div class="col-12 col-md-6">
        <div class="mb-4">
          <h4>{{ $t('mortgage.result') }}</h4>
          <hr />
          <div class="monthly--payment px-2">
            <span class="mr-2">{{ $t('mortgage.monthlyAmount') }}: </span>
            <span class="monthly--payment-total">{{
              $n(monthlyPayment, 'currency', 'en-SG')
            }}</span>
          </div>
        </div>
        <div class="px-2">
          <router-link :to="`/mortgage?listing_uuid=${listing.uuid}`" class="btn btn-primary">{{
            $t('mortgage.enquireMortgage')
          }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';

export default {
  name: 'listing-detail-description',
  mixins: [LodashMixin],
  props: ['listing'],
  components: {
    Cleave,
  },
  computed: {
    ...mapState({
      ltv: state => state.v2.mortgage.ltv,
    }),
    purchasePrice: {
      get() {
        return this.purchasePriceValue;
      },
      set(val) {
        this.purchasePriceValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('S$', '').trim())
            : val
          : 0;
        this.submitCalculation();
      },
    },
    loanAmount: {
      get() {
        return this.loanAmountValue;
      },
      set(val) {
        this.loanAmountValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('S$', '').trim())
            : val
          : 0;
        this.submitCalculation();
      },
    },
  },
  data: () => ({
    cleaveOptionsPrice: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      prefix: 'S$ ',
    },
    purchasePriceValue: 0,
    loanAmountValue: 0,
    tenureInYear: 0,
    interestRate: 0,
    monthlyPayment: 0,
  }),
  methods: {
    async setupCalculator() {
      await this.$store.dispatch('v2/mortgage/getLTV', this.listing);
      this.purchasePrice = this.listing ? this.listing.sell_price : 0;
      this.loanAmount = parseInt(this.purchasePrice * this.ltv);
      this.interestRate = 0.9;
      this.tenureInYear = 5;
      this.calculateMortgage();
    },
    calculateMortgage() {
      let monthlyInterestRate = this.interestRate / 100 / 12;
      let tenureInMonths = this.tenureInYear * 12;
      let totalInterestRate = Math.pow(1 + monthlyInterestRate, tenureInMonths);
      // console.log('qwe 1', monthlyInterestRate);
      // console.log('qwe 2', tenureInMonths);
      // console.log('qwe 3', totalInterestRate);
      // console.log('qwe 4', this.loanAmountValue);
      this.monthlyPayment =
        (this.loanAmountValue * totalInterestRate * monthlyInterestRate) / (totalInterestRate - 1);
    },
    async submitCalculation() {
      const isValid = await this.$validate();
      if (isValid) {
        this.calculateMortgage();
      }
    },
  },
  mounted() {
    this.setupCalculator();
  },
  validators: {
    purchasePrice(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.purchasePrice.required'))
        .custom(() => {
          if (this.purchasePriceValue <= 0) {
            return `Purchase Price must be a valid amount.`;
          }
        });
    },
    loanAmount(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.loanAmount.required'))
        .custom(() => {
          if (this.loanAmountValue > this.purchasePriceValue * this.ltv) {
            return `Maximum Loan Amount is ${this.ltv * 100}% of the Purchase Price.`;
          }
        });
    },
    interestRate(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.interestRate.required'))
        .custom(() => {
          if (this.interestRate <= 0) {
            return `Interest Rate must be a valid amount.`;
          }
        });
    },
    tenureInYear(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.tenureInYear.required'))
        .custom(() => {
          if (this.tenureInYear <= 0) {
            return `Loan Tenure must be a valid amount.`;
          }
        });
    },
  },
  watch: {
    interestRate() {
      this.submitCalculation();
    },
    tenureInYear() {
      this.submitCalculation();
    },
  },
};
</script>
